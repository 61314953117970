// @import '~antd/es/style/themes/default.less';

.field {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .label
  .number {
    font-size: 14px;
    line-height: 22px;
  }
  .number {
    margin-left: 8px;
    color: fade-in(#000000, 0.85);
  }
}