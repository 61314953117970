.sn-btn-big {
  min-width: 100px;
  height: 34px;
  padding: 4px 14px;
}
.sn-button {
  .svg-inline--fa + span {
    margin-left: 6px;
  }
}
.sn-button-scroll-top {
  left: 90px;
  bottom: 20px
}

.sn-btn-purple {
  background: #f9f0ff !important;
  border: 1px solid #722ed1 !important;
  .anticon {
    color: #722ed1;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #722ed1 !important;
  }
}
.sn-btn-green {
  background: #f6ffed !important;
  border: 1px solid #52c41a !important;
  color: #52c41a !important;
  .anticon {
    color: #52c41a !important;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #52c41a !important;
  }
}
.sn-btn-blue {
  background: #e6f7ff !important;
  border: 1px solid #e6f7ff !important;
  .anticon {
    color: #42a5f5;
  }
  .fal {
    color: #42a5f5 !important;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #42a5f5 !important;
  }
}
.sn-btn-red {
  background: #fff1f0 !important;
  border: 1px solid #fff1f0 !important;

  .fal,
  .far,
  .fas,
  svg,
  .anticon {
    color: #f5222d !important;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #f5222d !important;
  }
}
.sn-btn-link {
  span {
    font-weight: 600;
  }
}
