@import "./base.scss";
@import "./assets/css/bootstrap.css";

.notify-day-number {
  width: 60px;
}
.sn-form-text {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  font-weight: 300;
}
.ant-picker {
  width: 100%;
}
.ant-form-large .ant-form-item-label > label {
  height: 29px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  background: transparent;
}
::-webkit-scrollbar:hover {
  background-color: #fff;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

::-webkit-scrollbar-button {
  display: none;
}
.sn-btn-hoverable {
  cursor: pointer;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  will-change: transform;
  &:hover {
    transform: scale(1.2);
  }
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}
kendo-pdf-export {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

.sn-input-addon {
  display: table !important;
}

.ant-input-group.ant-input-group-compact
  > *:first-child
  .sn-form-duration-item {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.ant-input-group.ant-input-group-compact > *:last-child .sn-form-duration-item {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 18px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.sn-tabs-container > .ant-tabs-card .sn-tabs-tabpane {
  margin-top: -18px;
}
.sn-tabs-container .ant-tabs-nav-wrap {
  z-index: 1;
}

.sn-tabs-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.sn-tabs-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"]
  .sn-tabs-container
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: #fff;
  z-index: 1;
}

.sn-form-collapse {
  margin-top: 8px;
  .ant-collapse-header {
    color: $primary-color !important;
  }
  &-panel {
    .ant-collapse-content {
      padding-top: 8px;
    }
  }
}

::-moz-placeholder {
  color: #bfbfbf !important;
  opacity: 0.8 !important;
}
:-ms-input-placeholder {
  color: #bfbfbf !important;
  opacity: 0.8 !important;
}
::-webkit-input-placeholder {
  color: #bfbfbf !important;
}
::placeholder {
  color: #bfbfbf !important;
  opacity: 0.9 !important;
}
// .ant-form-item-control-input-content {
//   ::-moz-placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
//   :-ms-input-placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
//   ::-webkit-input-placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
//   :placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
// }
// .ant-upload-drag-container {
//   padding: 0 12px;
// }
.ant-typography {
  color: inherit;
}
.ant-tag {
  font-weight: initial;
  .ant-typography {
    font-weight: initial;
  }
}
.sn-list-item-meta-data {
  .ant-list-item-meta-title {
    margin: 0 !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }
  .ant-list-item-meta-content {
    margin-top: -4px;
  }
}

.option-item-has-image {
  .ant-list-item-meta-avatar {
    margin-right: 12px;
  }
  .ant-list-item-meta-content {
    margin-top: -3px;
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-item-meta-description {
      font-size: 12px;
    }
  }
}
.sn-select-option-item {
  .ant-list-item-meta-content {
    margin-top: -3px;
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-item-meta-description {
      font-size: 14px;
    }
  }
}
.sn-user-item {
  .ant-list-item-meta-title {
    margin: 0 !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }
  .ant-list-item-meta-content {
    margin-top: -4px;
  }
}

.sn-bg-grey {
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
}

label {
  font-weight: normal;
}

.user-meta-data {
  .ant-card-meta-title {
    font-size: 16px;
    margin-bottom: 0px !important;
  }

  .far {
    color: black;
  }

  .details-icon-item {
    p {
      position: relative;
      margin-bottom: 8px;
      .fas {
        width: 14px;
      }
    }
  }
}
.ant-picker-ok {
  .ant-btn {
    padding: 2px 26px !important;
    height: 30px;
  }
}
.ant-picker-footer {
  min-width: 140px !important;
  width: 100%;
}
.ant-image-preview-root {
  .ant-image-preview-mask {
    z-index: 1010 !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}
.sn-meta-item {
  width: calc(100% - 8px);
  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-card-meta-avatar {
    padding-right: 10px;
  }
}

.sn-details-item-with-icon {
  display: flex;
}
.ant-list-item-meta {
  h4 {
    margin-top: 0 !important;
  }
}

.sn-border {
  border: 1px solid #d9d9d9;
}

.sn-dropdown-item-selected {
  background-color: $primary-color;
  border-radius: 4px;
  padding: 5px 12px;
  span {
    color: #fff;
  }
  &:hover {
    span {
      color: #000;
    }
  }
}

.sn-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sn-form {
  .ant-row {
    .ant-col {
      section {
        margin-bottom: 12px;
      }
    }
  }
}

.ant-select-selector {
  width: 100%;
}

.custom-select-option {
  display: flex;
}

.custom-select-option {
  > .option-img {
    border: 1px solid #e0e0e0;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-right: 6px;

    > img {
      max-width: 32px;
      max-height: 32px;
    }
  }
  > .option-text {
    line-height: 16px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
    > span {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  > .option-right-text {
    float: right;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sn-meta-item {
  .ant-card-body {
    padding: 10px;

    .ant-card-meta-avatar {
      padding-right: 10px;
    }

    .ant-card-meta-title {
      font-size: 14px;
      margin-bottom: 0 !important;
    }
  }
}
.sn-items-list {
  .ant-list-item {
    border-bottom: none;
    padding-top: 0;
  }
}
