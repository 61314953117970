$primary-border-radius-base: 4px;
$details-card-height: 480px;
$side-bar-width: 185px;
$primary-margin: 12px;
$primary-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2),
  0 1px 2px 0 rgba(0, 0, 0, 0.06);

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$code-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
  monospace;

//Color
$primary-color: #42a5f5;
$primary-color-error: #f50;
$primary-color-warning: #ff9800;
$primary-color-success: #5ac102;
$primary-color-processing: #42a5f5;

$font-size-base: 14px;
$text-color-secondary: rgba(0, 0, 0, 0.45);
$text-color: #000;
$heading-color: rgba(0, 0, 0, 0.85);
$border-color-split: #f0f0f0;
$input-placeholder-color: hsv(0, 0, 75%);
//Avartar
$avatar-size-base: 32px;
$avatar-size-lg: 40px;
$avatar-size-sm: 24px;
$avatar-font-size-base: 18px;
$avatar-font-size-lg: 24px;
$avatar-font-size-sm: 14px;
$avatar-bg: #ccc;
$avatar-color: #fff;

// Border color
$border-color-base: hsv(0, 0, 85%); // base border outline a component
$border-color-split: hsv(0, 0, 94%); // split border inside a component
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

.notify-day-number {
  width: 60px;
}
.sn-form-text {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  font-weight: 300;
}
.ant-picker {
  width: 100%;
}
.ant-form-large .ant-form-item-label > label {
  height: 29px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  background: transparent;
}
::-webkit-scrollbar:hover {
  background-color: #fff;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

::-webkit-scrollbar-button {
  display: none;
}
.sn-btn-hoverable {
  cursor: pointer;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  will-change: transform;
  &:hover {
    transform: scale(1.2);
  }
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}
kendo-pdf-export {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact .ant-input {
  border-radius: 0 !important;
}

.sn-input-addon {
  display: table !important;
}

.ant-input-group.ant-input-group-compact
  > *:first-child
  .sn-form-duration-item {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.ant-input-group.ant-input-group-compact > *:last-child .sn-form-duration-item {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 18px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.sn-tabs-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -18px;
}
.sn-tabs-container .ant-tabs-nav-wrap {
  z-index: 1;
}

.sn-tabs-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.sn-tabs-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"]
  .sn-tabs-container
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: #fff;
  z-index: 1;
}

.sn-form-collapse {
  margin-top: 8px;
  .ant-collapse-header {
    color: $primary-color !important;
  }
  &-panel {
    .ant-collapse-content {
      padding-top: 8px;
    }
  }
}

::-moz-placeholder {
  color: #bfbfbf !important;
  opacity: 0.8 !important;
}
:-ms-input-placeholder {
  color: #bfbfbf !important;
  opacity: 0.8 !important;
}
::-webkit-input-placeholder {
  color: #bfbfbf !important;
}
::placeholder {
  color: #bfbfbf !important;
  opacity: 0.9 !important;
}
// .ant-form-item-control-input-content {
//   ::-moz-placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
//   :-ms-input-placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
//   ::-webkit-input-placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
//   :placeholder {
//     color: #bfbfbf !important;
//     opacity: 0.8 !important;
//   }
// }
// .ant-upload-drag-container {
//   padding: 0 12px;
// }
// .ant-typography {
//   color: inherit;
// }
// .ant-tag {
//   font-weight: initial;
//   .ant-typography {
//     font-weight: initial;
//   }
// }
.sn-list-item-meta-data {
  .ant-list-item-meta-title {
    margin: 0 !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }
  .ant-list-item-meta-content {
    margin-top: -4px;
  }
}

.option-item-has-image {
  .ant-list-item-meta-avatar {
    margin-right: 12px;
  }
  .ant-list-item-meta-content {
    margin-top: -3px;
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-item-meta-description {
      font-size: 12px;
    }
  }
}
.sn-select-option-item {
  .ant-list-item-meta-content {
    margin-top: -3px;
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-item-meta-description {
      font-size: 14px;
    }
  }
}
.sn-user-item {
  .ant-list-item-meta-title {
    margin: 0 !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }
  .ant-list-item-meta-content {
    margin-top: -4px;
  }
}

.sn-bg-grey {
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
}

label {
  font-weight: normal;
}

.user-meta-data {
  .ant-card-meta-title {
    font-size: 20px;
    margin-bottom: 0px !important;
  }

  .far {
    color: black;
  }

  .details-icon-item {
    p {
      display: block;
      margin-bottom: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-word;
      transition: color 0.3s;
    }
  }
}
.ant-picker-ok {
  .ant-btn {
    padding: 2px 26px !important;
    height: 30px;
  }
}
.ant-picker-footer {
  min-width: 140px !important;
  width: 100%;
}
.ant-image-preview-root {
  .ant-image-preview-mask {
    z-index: 1010 !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}
.sn-meta-item {
  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }
}
