.sn-round-spin {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
  .sn-round-spin-bounce1,
  .sn-round-spin-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .sn-round-spin-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sn-spin-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sn-spin-chase 2.5s infinite linear both;

  .sn-spin-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sn-spin-chase-dot 2s infinite ease-in-out both;
  }

  .sn-spin-chase-dot:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;

    border-radius: 100%;
    animation: sn-spin-chase-dot-before 2s infinite ease-in-out both;
  }

  .sn-spin-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
    &::before {
      animation-delay: -1.1s;
      background-color: #1890ff;
    }
  }
  .sn-spin-chase-dot:nth-child(2) {
    animation-delay: -1s;
    &::before {
      animation-delay: -1s;
      background-color: #40a9ff;
    }
  }
  .sn-spin-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
    &::before {
      animation-delay: -0.9s;
      background-color: #69c0ff;
    }
  }
  .sn-spin-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
    &::before {
      animation-delay: -0.8s;
      background-color: #91d5ff;
    }
  }
  .sn-spin-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
    &::before {
      animation-delay: -0.7s;
      background-color: #bae7ff;
    }
  }
  .sn-spin-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
    &::before {
      animation-delay: -0.6s;
      background-color: #e6f7ff;
    }
  }
}

@keyframes sn-spin-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sn-spin-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sn-spin-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.sn-spin-container {
  .sn-spin-tip {
    margin-top: 8px;
    text-shadow: 0 1px 2px #fff;
    line-height: 1.5715;
    color: #1890ff;
  }
}
.sn-loeader-center-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
