.login-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #42a5f5;
  background-image: url("../../../assets/img/bg_pattern.png");
}

.login {
  max-width: 350px;
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}

.login > img {
  padding-bottom: 50px;
  width: 250px;
  padding-top: 30px;
  margin: 0 auto;
  display: block;
}
