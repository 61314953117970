.sn-card-content-body {
  margin-left: -20px;
  margin-bottom: 20px;
  margin-right: -100px;

  &.top {
    margin-top: -20px;
  }
}

.sn-card-title {
  margin-top: 20px;
}

.sn-table-inside-card {
  margin-top: 10px;
  margin-left: -20px;
  margin-right: -20px;
}
