.sn-entity-image-icon {
  color: #777 !important;
  background-color: #fff !important;
  border: 1px solid rgb(217, 217, 217);
}
.sn-image-fill {
  img {
    object-fit: fill !important;
  }
}
