@import '../../../base.scss';

.menu {
  :global(.anticon) {
    margin-right: 8px;
  }
  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
  .action {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > span {
      vertical-align: middle;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    &:global(.opened) {
      background: rgba(0, 0, 0, 0.025);
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      margin: 'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      color: $primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark {
  .action {
    color: rgba(255, 255, 255, 0.85);
    > span {
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover,
    &:global(.opened) {
      background: $primary-color;
    }
  }
}

:global(.ant-pro-global-header) {
  .dark {
    .action {
      color: $text-color;
      > span {
        color: $text-color;
      }
      &:hover {
        color: rgba(255, 255, 255, 0.85);
        > span {
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
}
