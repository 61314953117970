.sn-content {
  .sn-content-header {
    padding: 0 0 10px 0;
    position: fixed;
    z-index: 1001;
    height: 48px;
    top: 0;
    display: flex;
    align-items: flex-end;
  }
  @media (max-width: 767px) {
    .sn-content-header {
      position: relative;
      background: #fff;
      text-align: center;
      margin-bottom: 20px;
      border-radius: 4px;
      box-shadow: 1px 1px 3px #bfbfbf;
      z-index: 0;
    }

    .sn-content-body {
    }
  }
}
