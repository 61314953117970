.export-node-modal {
  .ant-modal-body {
    height: calc(100vh - 155px);
    width: calc(100vw - 400px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px 24px 0 24px;
  }

  .ant-card-body {
    padding: 0px;
  }
}

.checkbox-group {
  margin: 15px 0px 0px 5px;
}
