.sn-group-actions-list {
  .ant-btn-icon-only {
    background-color: #fbfbfb;
    .fal {
      font-size: 14px;
      color: #707070;
    }
    .anticon {
      color: #707070;
    }
  }
  > .ant-btn,
  > a > .ant-btn {
    width: 34px;
    height: 32px;
  }
}
.sn-button-group {
  display: inline-flex;

  .ant-btn-primary,
  .ant-btn-sm {
    border-radius: 4px !important;
  }
  > .ant-btn:first-child:not(:last-child),
  > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  > .ant-btn:last-child:not(:first-child),
  > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  > a:first-child:not(:last-child) .ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  > a:last-child:not(:first-child) .ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  > a:only-child .ant-btn,
  > .ant-btn:only-child {
    border-radius: 4px !important;
  }
}
.sn-group-btn-icon {
  .fal,
  .fas {
    color: rgba(0, 0, 0, 0.45);
  }
  &:hover,
  &:active,
  &:focus {
    .fal,
    .fas {
      color: #42a5f5;
    }
  }
}
.sn-btn-nav-matched {
  background-color: #42a5f5;
  border: 1px solid #42a5f5;

  .fal {
    color: #fff;
  }
  &:hover {
    background-color: #42a5f5;
  }
}
