.sn-details-card {
  // height: 480px;
}

.sn-details-card .ant-card-body {
  // overflow: auto;
  // height: 85%;
}

.sn-details-card .asset-map {
  margin-top: 0;
  margin: -19px;
}

.sn-form-card .ant-card-body {
  padding: 6px 24px 24px;
}

.sn-heading-card > .ant-card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: unset !important;
  height: 100% !important;
}

.sn-heading-card > .ant-card-body .sn-panel-heading-left,
.sn-heading-card > .ant-card-body .sn-panel-heading-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sn-heading-card > .ant-card-body .sn-panel-heading-left > section:not(:first-child),
.sn-heading-card > .ant-card-body .sn-panel-heading-right > section:not(:first-child) {
  margin-left: 10px;
}

.sn-heading-card > .ant-card-body .sn-panel-heading-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.sn-heading-card > .ant-card-body:before, .sn-heading-card > .ant-card-body:after {
  content: none;
}

@media (max-width: 768px) {
  .sn-heading-card > .ant-card-body {
    display: block;
  }
  .sn-heading-card > .ant-card-body .sn-panel-heading-right {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 5px;
  }
  .sn-heading-card > .ant-card-body .sn-panel-heading-left {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.sn-heading-card .ant-card-body {
  padding: 12px 20px;
}

.sn-panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.sn-panel .ant-card-head {
  padding: 0 20px;
  min-height: 40px;
}

.sn-panel .ant-card-head .ant-card-head-wrapper {
  min-height: 54px;
}

.sn-panel .ant-card-head .ant-card-head-title {
  color: #42a5f5;
  font-weight: 300;
  font-size: 18px;
  padding: 12px 0;
}

.sn-panel .ant-card-head .ant-card-extra {
  padding: 12px 0;
}

.sn-list-card .ant-card-body {
  padding: 5px 12px 2px;
}
/*# sourceMappingURL=index.css.map */