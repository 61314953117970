.sn-img-crop-control {
  display: flex;
  align-items: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  button {
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    padding: 0;
    font-style: normal;
    background: transparent !important;
    border: 0;
    outline: 0;
  }
  .ant-slider {
    flex: 1;
    margin: 0 8px;
  }
}

.sn-cropper-pre-view {
  margin-right: 0 !important;
}
