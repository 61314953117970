.side-filter-badge .ant-badge-count {
  background-color: transparent;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.side-status-tag-badge .ant-badge-count {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset;
}

.sn-badge-trans .ant-badge-count {
  background-color: transparent;
  color: #42a5f5;
  font-size: 18px;
  box-shadow: 0 0 0 1px #42a5f5 inset;
}
.sn-badge-trans-small .ant-badge-count {
  background-color: transparent;
  color: #42a5f5;
  box-shadow: 0 0 0 1px #42a5f5 inset;
}
.sn-sidebar-pending-badge .ant-badge-count {
  background-color: #d84a1b;
  color: #fff;
  box-shadow: 0 0 0 1px #d84a1b inset;
}
.sn-badge-select-item .ant-badge-count {
  background-color: transparent;
  color: #999;
  box-shadow: 0 0 0 1px #999 inset;
  margin-left: 5px;
}

.sn-badge-tab-item .ant-badge-count {
  background-color: transparent;
  margin-left: 4px;
  box-shadow: 0 0 0 1px #999 inset;
  color: #999;
}

.ant-tabs-tab-active {
  .sn-badge-tab-item .ant-badge-count {
    color: #1890ff;
    box-shadow: 0 0 0 1px #1890ff inset;
  }
}
