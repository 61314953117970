.sn-user-avatar-list {
  .ant-avatar {
    width: 31px;
    height: 31px;

    border: 2px solid #fff;
    cursor: pointer;

    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: translateZ(10);
      transform: translateY(-3px);
    }
  }

  .ant-avatar-string {
    font-size: 14px;
    line-height: 26px !important;
  }
}
