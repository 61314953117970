.sn-process-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(48, 59, 64, 0.34);
  z-index: 99999;

  .sn-process-spin {
    background-color: #fff;
    padding: 24px 64px;
    border-radius: 8px;
  }
}
