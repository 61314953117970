.sn-section-title {
  font-size: 14px;
  border-bottom: 1px solid #42a5f5;
  color: #42a5f5;
  line-height: 2em;
  margin-bottom: 14px;
  font-weight: 300;

  .sn-section-title-top {
    display: flex;

    .sn-section-title-top-left {
      flex: 0.7;
    }

    .sn-section-title-top-right {
      display: flex;
      flex: 0.3;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
