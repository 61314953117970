@import "../../../../base.scss";
.sn-checkbox-input-item {
  margin-bottom: 0;
  .ant-form-item-control-input {
    min-height: 0;
  }
}

.sn-form-item {
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;

  .ant-input-prefix {
    margin: 0 2px 1px 3px;
  }
  .ant-form-item-label {
    margin-bottom: -4px;
    label {
      width: 100%;
    }
    .sn-form-item-label-has-right {
      width: 100%;
      .ant-btn-link {
        padding: 0;
        height: 25px;
      }
    }
  }
  .ant-input-clear-icon:last-child {
    margin-right: 4px;
  }
  .ant-input-lg {
    padding: 6.5px 17px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0 0 0 6px;
  }
  .ant-form-item-explain-error {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.sn-select-option {
  display: grid;
  color: rgba(0, 0, 0, 0.65);

  .sn-select-option-subtitle {
    color: rgba(0, 0, 0, 0.45);
  }
}

.sn-filter-form-item-wrapper {
  .ant-form-item-control {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    label {
      color: $primary-color;
      font-weight: 400;
      width: 100%;
      > div {
        width: 100%;
      }

      .sn-form-label-right-btn {
        margin-right: 4px;
        cursor: pointer;

        transition: transform 0.3s ease-in-out,
          -webkit-transform 0.3s ease-in-out;
        will-change: transform;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
  .ant-form-item-control {
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}
.sn-btn-filter {
  transition: width 0.3s linear, -webkit-transform 0.3s ease-in-out;
}
.sn-form-item-radio-no-checkbox {
  .ant-radio-wrapper {
    .ant-radio {
      display: none;
    }
  }
  .ant-radio-wrapper-checked {
    color: $primary-color;
    font-weight: 500;
  }
}
.sn-form-item-has-add-new {
  margin-bottom: 0;

  margin-bottom: 8px;

  .ant-form-item-label {
    padding: 0;
    label {
      width: 100%;
      > div {
        width: 100%;
      }
    }
    .ant-btn {
      height: auto;
      padding: 0;
    }
  }
}

.ant-select-loading {
  .ant-select-selection-item {
    visibility: hidden !important;
    display: none !important;
    .ant-select-selection-item-content {
      visibility: hidden !important;
      display: none !important;
    }
  }
}

.sn-form-item-horizon {
  display: flex;

  align-items: center;

  flex-direction: inherit !important;

  margin-bottom: 0;
  .ant-form-item-label {
    padding: 0 12px;
  }
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact .ant-input {
  border-radius: 0 !important;
}
.sn-form-item-checkbox-group {
  .ant-checkbox-group-item {
    display: block;
  }
}
