@import "../../../base.scss";

.sn-modal {
  .ant-modal-header {
    padding: 12px 24px;
    background-color: $primary-color;
    .ant-modal-title {
      color: #fff;
    }
  }

  .ant-modal-footer {
    .sn-modal-left-footer {
      float: left !important;
      padding: 8px;
    }
  }
  .ant-modal-close-x {
    line-height: 50px;
  }
}
.sn-modal-body-title {
  font-size: 18px;
  font-weight: bold;
  color: $primary-color;
  margin: 0;
  margin-bottom: 10px;
}

.sn-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
}

.sn-tabs-container p {
  margin: 0;
}

.sn-modal-no-title {
  .ant-modal-header {
    background-color: #fff;
    border: none;
    padding: 8px 24px;
  }
}
.sn-form-modal {
  .ant-modal-body {
    max-height: 540px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
