@import "../../../../base.scss";
.sn-logo-form-item-container {
  .ant-image {
    height: 150px;
    position: relative;
    .ant-image-img {
      border-radius: 4px;
      max-width: 200px;
      width: auto;
      max-height: 100%;
    }
  }
}
@media (max-width: $screen-xs) {
  .sn-logo-form-item-container {
    .ant-image {
      height: 100px;
      .ant-image-img {
        max-width: 150px;
      }
    }
  }
}
@media (min-width: $screen-md) and (max-width: $screen-xl) {
  .sn-logo-form-item-container {
    .ant-image {
      height: 100px;
      .ant-image-img {
        max-width: 120px;
      }
    }
  }
}
