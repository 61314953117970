@import "../../../base.scss";

.sn-editor-wrapper {
  box-shadow: $primary-box-shadow;
  font-family: Roboto;

  .editor-content {
    height: auto;
    max-height: calc(100vh - 170px);
    min-height: 250px;
  }
}
