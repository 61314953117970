.no-data-found {
  text-align: center;
  opacity: 0.6;
  font-size: 20px;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.45);

  .no-data-found-icon {
    display: inline-block;
    opacity: 0.5;
    margin-bottom: 8px;
  }
  .no-data-found-message {
    span {
      font-weight: 400;
    }
  }
}
